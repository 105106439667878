var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.returnIndividualUser)?_c('div',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{ref:"previewEl",attrs:{"src":_vm.urlImage ? _vm.urlImage : _vm.avatar,"text":_vm.avatarText(_vm.returnIndividualUser.user.fullname),"size":"102px","rounded":""}})]},proxy:true}],null,false,3536831486)},[_c('h4',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.returnIndividualUser.user.fullname)+" ")]),_c('div',{staticClass:"d-flex flex-wrap mb-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.$el.click()}}},[_vm._v(" "+_vm._s(_vm.$t("Carregar imagem"))+" ")]),_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","hidden":true,"plain":""},on:{"change":_vm.onFileChange}}),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"outline-secondary"},on:{"click":function () { return ((_vm.profileFile = 'remove'), (_vm.urlImage = 'xxx')); }}},[_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("Remover imagem")))]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"TrashIcon"}})],1)],1),_c('b-card-text',{class:_vm.profileFile && 'text-warning'},[_vm._v(" "+_vm._s(_vm.profileFile && _vm.urlImage.lenght > 3 ? _vm.$t("Imagem selecionada: ") + _vm.profileFile.name : _vm.$t("JPG, GIF ou PNG permitidos. Tamanho máximo de 4MB"))+" ")])],1),_c('validation-observer',{ref:"generalUserEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.saveChange.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Usuário'),"label-for":"username"}},[_c('validation-provider',{attrs:{"mode":"eager","name":"Username","vid":"username","rules":"required|min:4|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false : null},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Nome do usuário'),"label-for":"fullname"}},[_c('validation-provider',{attrs:{"mode":"eager","name":"Fullname","vid":"fullname","rules":"required|min:6|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fullname","state":errors.length > 0 ? false : null},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$i18n.t('E-mail'),"label-for":"email"}},[_c('validation-provider',{attrs:{"mode":"eager","name":"email","vid":"email","rules":"required|min:6|max:80|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Status'),"label-for":"user-status"}},[_c('v-select',{attrs:{"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-status","disabled":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Contato'),"label-for":"Phone"}},[_c('validation-provider',{attrs:{"mode":"eager","name":"Phone","vid":"phone_number","rules":"max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_number","state":errors.length > 0 ? false : null},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Nova senha'),"label-for":"newPassword"}},[_c('validation-provider',{attrs:{"mode":"eager","name":"newPassword","vid":"newPassword","rules":"min:4|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"newPassword","state":errors.length > 0 ? false : null,"placeholder":"Nova senha"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex mt-1"},[_c('b-form-checkbox',{staticClass:"custom-control-danger",attrs:{"name":"check-button","switch":""},model:{value:(_vm.blockAccess),callback:function ($$v) {_vm.blockAccess=$$v},expression:"blockAccess"}}),_c('b-card-text',{class:_vm.blockAccess ? 'text-danger' : 'text-success'},[_vm._v(_vm._s(_vm.blockAccess ? "Acesso bloqueado!" : 'Acesso liberado!'))])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex mt-1"},[_c('b-form-checkbox',{staticClass:"custom-control-danger",attrs:{"name":"check-button","switch":""},model:{value:(_vm.withdrawalBlocked),callback:function ($$v) {_vm.withdrawalBlocked=$$v},expression:"withdrawalBlocked"}}),_c('b-card-text',{class:_vm.withdrawalBlocked ? 'text-danger' : 'text-success'},[_vm._v(_vm._s(_vm.withdrawalBlocked ? "Saque bloqueado!" : 'Saque liberado!'))])],1)])],1)],1)],1),_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.loading}},[(!_vm.loading)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Salvar alterações"))+" ")]):_c('div',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t("Carregando..."))+" ")],1)]),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.loadingDataProfile.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Redefinir"))+" ")])],1)]}}],null,false,3869980861)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }