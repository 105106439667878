<template>
  <component :is="returnIndividualUser === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="returnIndividualUser === undefined">
      <h4 class="alert-heading">{{ $t("Erro ao buscar dados do usuário") }}</h4>
      <div class="alert-body">
        {{ $t("Nenhum usuário encontrado com este ID de usuário. Verificar") }}
        <b-link class="alert-link" :to="{ name: 'administrator-users-list' }">
          {{ $t("Lista de usuários") }}
        </b-link>
        {{ $t("para outros usuários.") }}
      </div>
    </b-alert>

    <b-tabs v-if="returnIndividualUser" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Geral") }}</span>
        </template>
        <user-edit-tab-account
          class="mt-2 pt-75"
          @refresh-user-data="fetchUserData"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab disabled>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Outras informações") }}</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab disabled>
        <template #title>
          <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("Redes sociais") }}</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import UserEditTabAccount from "./UserEditTabAccount.vue";
import UserEditTabInformation from "./UserEditTabInformation.vue";
import UserEditTabSocial from "./UserEditTabSocial.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  computed: {
    ...mapGetters(["returnIndividualUser"]),
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      this.$store
        .dispatch("fetchUser", this.$route.params.id)
        .catch((error) => {
          this.userData = undefined;
        });
    },
  },
};
</script>

<style>
</style>
